import { showSuccessNotification } from "@/composables/useNotifications";
import i18n from "@/setup/i18n";
import store from "@/store";
import { computed } from "vue";
import { DOMAIN_TO_APP_NAME } from "@/utils/const";

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z0-9]/g, (ltr, idx) =>
    idx > 0 ? `_${ltr.toLowerCase()}` : ltr.toLowerCase()
  );

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const truncate = (text, stop, endChar) => {
  if (!text) {
    return "";
  }
  return text.slice(0, stop) + (stop < text.length ? endChar || "..." : "");
};

export function toCamelCase(inputString) {
  return inputString
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
    .replace(/[^a-zA-Z0-9]+/g, "");
}

export const chainFilters =
  (...filterFunctions) =>
  (item) => {
    return filterFunctions
      .map((filterFn) => filterFn(item))
      .every((filterFnResult) => filterFnResult === true);
  };

export const icontains = (item, keyVal) => {
  const [key, value] = Object.entries(keyVal)[0];

  if (value === null || !value) return true;

  if (Array.isArray(item[key]) && Array.isArray(value)) {
    return value.some((el) => item[key].includes(el));
  }

  return item[key].toLowerCase().includes(value?.toLowerCase());
};

export const exact = (item, keyVal) => {
  const [key, value] = Object.entries(keyVal)[0];
  if (value === null) return true;
  if (item[key] != value?.toLowerCase()) {
    return false;
  }
  return true;
};

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const getArraySum = (array) => {
  return array.reduce((pre, curr) => +pre + +curr, 0);
};

export const setReadableNumber = (value, locale = "fr") => {
  // Value must be a number
  // Add spaces each 3 digits (or comma, depending on the region)
  return value.toLocaleString(locale);
};

export const copyContentsToClipboard = (valueToCopy) => {
  navigator.clipboard.writeText(valueToCopy);
  showSuccessNotification(i18n.t("copiedToClipboard"));
};

export const formatFilters = (filters) => {
  return JSON.stringify(
    Object.fromEntries(
      Object.entries(filters).map(([key, value]) => [
        camelToSnakeCase(key),
        value,
      ])
    )
  );
};

export const readIntFormat = (value) => {
  const cleanValue = value.toString().replace(",", ".").replace(/\s/g, "");

  if (isNaN(cleanValue) || !value) return "";

  return new Intl.NumberFormat(i18n.locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(cleanValue));
};

export const writeIntFormat = (item, property, value) => {
  const inputFormatted = parseFloat(
    value.replace(",", ".").replace(/\s/g, "")
  ).toFixed(2);
  if (isNaN(inputFormatted)) {
    item[property] = "";
  } else {
    item[property] = inputFormatted;
  }
};

export const readFloatFormat = (value, toFixed) => {
  return parseFloat(value).toFixed(toFixed);
};
export const writeFloatFormat = (value, toFixed) => {
  if (value.includes(",")) {
    value.replace(",", ".");
  }
  return parseFloat(value).toFixed(toFixed);
};

export const currentUser = computed(() => store.getters["user/getCurrentUser"]);

export const computedGetter = (getter) => computed(() => store.getters[getter]);

export const getUuidSplit = (str) =>
  str && str.includes("-") ? str.split("-")[0] : str;

/**
Checks if a string starts with any of the given substrings (case-insensitive).
@param {string} str - The string to check.
@param {string[]} substrs - An array of substrings to check against.
@returns {boolean} - True if the string starts with any of the given substrings, false otherwise. 

*/

export function hasPrefix(str, substrs) {
  try {
    str = str.toLowerCase();
    substrs = substrs.map((substr) => substr.toLowerCase());
    return substrs.some((substr) => str.startsWith(substr));
  } catch (err) {
    console.log("Something went wrong", err);
  }
}

export const arrayNonPresentKeys = (data, keysArray) => {
  // For a data Object (dict), returns the keys that are not in the keysArray array
  return Object.keys(data).filter((k) => !keysArray.includes(k));
};

export const getFieldValueFromFieldsMap = (fieldsMap, field, valueType) => {
  // Returns the value of a field in a fieldsMap Object, given the field and the valueType
  const [, { [valueType]: value }] = fieldsMap.find(
    ([fieldName]) => fieldName === field
  );
  return value;
};

export const getAppNameFromUrl = () => {
  console.log(
    "location",
    window.location.origin,
    process.env.VUE_APP_SOCIO_GRPC_API_ENV
  );
  const debugUrl = new URL(window.location.origin);
  console.log("debugUrl", debugUrl);
  const [debugAppName] = debugUrl.hostname.split(".");
  console.log("debugAppName", debugAppName);
  if (process.env.VUE_APP_SOCIO_GRPC_API_ENV === "production") {
    const url = new URL(window.location.origin);
    const [appName] = url.hostname.split(".");
    return DOMAIN_TO_APP_NAME[appName] ?? "AMOS";
  }
  return "AMOS";
};
